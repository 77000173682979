<style scoped>
    .form-msg{@apply font-bold text-xs leading-3 }
    .error{@apply text-[#D53415]}
    .info{@apply text-gray-900}
</style>

<template>
    <div>
        <div :id="`${getRoute}_errorMsg`" :name="cls" class="form-msg" :class="cls"><slot/></div>
    </div>
</template>
<script>
export default {
  props:{
      cls:{type: String},
  },
  computed: {
    getRoute() {
        let path = this.$route.path == '/' ? '/login' : this.$route.path
        return path?.replace('/','');
    }
  },
}
</script>
