<template>
  <div id="app" :app-buildtime="buildtime">
    <main>
      <!-- width="18%" -->
        <notifications animation-type="velocity" :animation="animation"  id="toaster_text" group="auth" position="bottom center" :classes="'my-notification'" :duration="4000"
        :speed="500" :ignoreDuplicates="true" :reverse="false" :closeOnClick="true"  :pauseOnHover="true"/>
        <RouterView />
    </main>
  </div>
</template>

<script>
import { buildtime } from "../package.json"
import { mapGetters } from "vuex";
import { useNotification } from "@kyvg/vue3-notification";
import cryptojs from "crypto-js"
const { notify } = useNotification()
export default {
  data() {
    return {
      isOnline: navigator.onLine,
      isTabActive: true ,
      buildtime: buildtime
    };
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
      if (!this.isOnline) {
        notify({ group: 'auth', type: 'error', title: 'You are currently offline', text: `Please check your internet connection.` })
        this.websocketClose()
      }
      if (this.isOnline) {
        this.backgroundCheck()
      }
    },
    handleResize() {
      let windowWidth = window.innerWidth
      let windowHeight = window.innerHeight
      this.$store.commit('setWindowHeightWidth', { h: windowHeight, w: windowWidth })
      // if route in marketwatch
      if (windowWidth > 900 && this.$route.path == '/marketWatch') {
        this.$router.push('/dashboard')
      }
    },

    initiateHB() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },

    handleVisibilityChange() {
      if (!document.hidden) {
        this.isTabActive = true;
        this.backgroundCheck()
      } else {
        this.isTabActive = false;
      }
    },
    backgroundCheck(){
      if(this.$route.path != '/'){
        this.$store.dispatch('osf_ws/backGroundCheck','connect')
        this.$store.dispatch('ws/backGroundCheck','connect')
      }
      
    },
    websocketClose(){
      if(this.$route.path != '/'){
        this.$store.dispatch('osf_ws/websocketClose','connect')
      this.$store.dispatch('ws/websocketClose','connect')
      }
    }
  },
  mounted() {
    window.addEventListener('offline', this.updateOnlineStatus());
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('online', this.updateOnlineStatus());
    this.handleResize();
    this.initiateHB();
  },

  computed: {
    ...mapGetters("Notification", ["getNotificationMsg"]),
    ...mapGetters("ws", ["getConnectionStatus"]),
    animation() {
      return {
        /**
         * Animation function
         *
         * Runs before animating, so you can take the initial height, width, color, etc
         * @param  {HTMLElement}  element  The notification element
         */
        enter(element) {
          let height = element.clientHeight
          return {
            // animates from 0px to "height"
            height: [height, 0],

            // animates from 0 to random opacity (in range between 0.5 and 1)
            opacity: [0.9]
          }
        },
        leave: {
          height: 0,
          opacity: 0
        }
      }
    }
  },
  unmounted() {
    this.websocketClose()
    window.removeEventListener("offline", this.updateOnlineStatus());
    window.removeEventListener('online', this.updateOnlineStatus());
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange); // remove the event listener when the component is unmounted
  },

};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,300");

:root {
  --ul-color: rgba(17, 133, 247, 1);
  --ul-d-color: rgba(0, 113, 224, 1);
}

.fw-100 {
  width: 100%;
}

body {
  font-family: "Open Sans", sans-serif !important;
}

.web-section {
  padding: 80px 0;
  float: left;
  width: 100%;
}

.head {
  text-align: center;
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 40px;
  color: #363344;
  margin-bottom: 60px;
}

.topic {
  float: left;
  width: 100%;
}

.sub-topic {
  float: left;
  width: 100%;
  font-size: 20px;
  color: #757283;
  font-weight: 400;
}

.topic-detail {
  float: left;
  width: 100%;
}

.title {
  text-align: center;
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  color: #363344;
  color: #1185f7;
}

.sub-title {
  text-align: center;
  float: left;
  width: 100%;
  font-size: 18px;
  color: #000000;
}

.sentence {
  width: 100%;
  color: #757088;
  font-size: 16px;
}

.table-content {
  color: #282828;
  font-size: 14px !important;
}

.table-footer-content {
  color: #000000;
  font-size: 22px !important;
}

.neagtiveColor {
  color: #ec413d;
  font-size: 22px !important;
}

.top16 {
  top: 16px !important;
}

.w-642 {
  width: 642px !important;
}

#header {
  width: 100%;
  height: 486px;
}

.min-wid-200 {
  min-width: 200px;
}

.customMWSelect {
  background-color: #ffffff;
  z-index: 999999999;
  position: absolute;
  top: 0;
  width: 100%;
  border: solid 1px#d7d7d7;
  cursor: pointer !important;
  @apply dark:border-[#232325];
}

.customMwlist {
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer !important;
}

.customMwlist:hover {
  background-color: #f9faff;
}

.mwSelectDiv {
  height: 46px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer !important;
}

.alignButton {
  padding: 7.5px 12px;
}

.fsize20 {
  font-size: 20px;
}

.fsize24 {
  font-size: 24px;
}

.padd-lft-5-per {
  padding-left: 5%;
}

.align-end {
  display: flex;
  justify-content: space-between;
}

.red-btn-c {
  background-color: #fceeed;
  color: #df514c;
  @apply dark:bg-[#2D1E1E] dark:text-[#DF514C] !font-semibold;
}

.green-btn-c {
  background-color: #eef8ee;
  color: #50b554;
  @apply dark:bg-[#4CAF501A] dark:text-[#5B9A5D] !font-semibold;
}

.blue-btn-c {
  background-color: #e7f3fe;
  color: #1185f7;
  @apply dark:bg-[#1D242E] dark:text-[#4987EE] !font-semibold;
}

.gray-btn-c {
  background-color: #f1f1f2;
  color: #323232;
  @apply dark:bg-[#BDBDBD1A] dark:text-[#BBBBBB] !font-semibold;
}

.orange-btn-c {
  background-color: #fff5ec;
  color: #ff9900;
  @apply dark:text-[#ffa319] dark:bg-[#28201a] !font-semibold;
}

.purple-btn-c {
  background-color: #f4ebff;
  color: #913aff;
  @apply dark:text-[#844AEB] dark:!bg-[#252327] !font-semibold;
}

.pink-btn-c {
  background-color: #fff2fa;
  color: #ff00a8;
  @apply dark:text-[#c53794] dark:bg-[#33242d] !font-semibold;
}

.fcolor-4caf50 {
  color: #1f921b !important;
}

.fcolor-6B7280 {
  color: #6b7280 !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.scrip-info::-webkit-scrollbar {
  width: 6px;
}
.scrip-info::-webkit-scrollbar-thumb {
  @apply dark:!bg-zinc-800 dark:!border-[#232325] bg-[#a2a2a2]  rounded-[20px] h-1 
}

.basket-search,
.option-chain::-webkit-scrollbar {
  width: 3px;
}

.edis-content::-webkit-scrollbar-thumb {
  @apply dark:!bg-zinc-800 dark:!border-[#232325] bg-[#ededed]  rounded-[20px] h-1 
}

.search-s-list::-webkit-scrollbar {
  width: 3px;
}

.basket-table::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  @apply dark:!bg-zinc-800 dark:!border-[#232325]  rounded-[20px] h-1
}

.table-wrapper::-webkit-scrollbar {
  width: 3px;
}
.dropdownlist::-webkit-scrollbar {
  width: 3px;
}
.tableoverflow::-webkit-scrollbar {
  width: 3px;
}
@media only screen and (min-width: 1600px) {
  .mw-watch {
    width: 450px !important;
  }

  .customMWSelect {
    width: 448px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .mw-watch {
    width: 475px !important;
  }

  .customMWSelect {
    width: 473px !important;
  }
}

.overflowinherit {
  overflow: inherit !important;
}

.border-bottom-ededed {
  border-bottom: solid 1px #ededed;
}

.mwSearchList {
  overflow-y: auto !important;
  max-height: calc(100vh - 220px) !important;
  top: 40px;
  @apply absolute w-full z-10;
}

.alertSearchList {
  overflow-y: auto !important;
  max-height: calc(100vh - 220px) !important;
  top: 84px;
  @apply absolute w-full z-10 shadow-lg;
}

.disabledrow {
  background-color: #ededed64;
  color: #8d8e91 !important;
  @apply dark:bg-neutral-800 
}
.disabledrow button {
  @apply dark:!bg-[#3d3d3d]
}
.tooltipClass {
  max-width: 300px !important;
}

/* input {
  caret-color: #000000;
} */

.searchInput input::placeholder {
  @apply text-xs;
}

.dark .divide-y {
  border-color: #232325 !important;
}

.table-common tr {
  @apply border-b border-[#ededed] dark:border-[#232323];
}

.table-common td {
  @apply font-medium dark:border-[#232323];
}

.primary-color {
  @apply !text-[#292929] !font-medium dark:!text-[#ffffffa3];
}

.secondary-color {
  @apply !text-[#0c0c0c] !font-medium dark:!text-[#8d8b8b];
}

.green-color {
  @apply !text-[#4CAF50] !font-medium;
}

.red-color {
  @apply !text-[#E34242] !font-medium;
}

.green-bg {
  @apply !bg-[#4CAF50];
}

.red-bg {
  @apply !bg-[#E34242];
}

.violet-color {
  @apply text-[#753ED7] font-medium;
}

.violet-bg {
  @apply !bg-[#753ED7];
}

.secondary-violet-bg {
  @apply !bg-[#F0F6FD] dark:!bg-[#27272A]
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"] {
  @apply !border-[#a4a5a7];
}

.background-img {
  background: url("./assets/img/bg-gradient.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.background-grid {
  background-repeat: no-repeat;
  background-position: center;
}

.calc-height-1 {
  min-height: calc(100vh - 70px);
}

.calc-height-2 {
  min-height: calc(100vh - 98px);
}

.calc-height-nodata {
  height: calc(100vh - 200px);
}

.hold-calc-height {
  height: calc(100vh - 196px);
}

.tooltipCusClass {
  @apply max-w-[320px] text-xs;
}

.spotLtpBtn {
  opacity: 1;
  top: 50px !important;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 1px;
  left: 0;
}

.spotLtpBtnOc {
  opacity: 1;
  top: 26px !important;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 1px;
  left: 0;
}

.oc-table tr.c-active td:before {
  @apply dark:border-gray-300;
  border: 1.5px solid #000000;
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.oc-table tr.cbot-active td:before {
  @apply dark:border-gray-300;
  border: 1.5px solid #000000;
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.oc-table tr.c-active {
  @apply relative;
}

.oc-table tr.cbot-active {
  @apply relative;
}

.spotLtpBtn .strike-price .strike-highlight {
  background: #0a2745;
  padding: 5px 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  display: inherit;
}

.spoLtpFloat {
  @apply text-xs px-2 py-[6px] border border-[ededed] dark:border-[#232325] bg-slate-800 text-white rounded min-w-[68px] cursor-default w-full
}

.spoLtpFloatOc {
@apply text-[10px] px-2 py-[2px] border border-[ededed] dark:border-[#232325] bg-slate-800 text-white rounded min-w-[68px] cursor-default
}

</style>

<style>
.my-notification {
  @apply relative;
  /* margin: 12px 12px 8px 0px; */
  margin: 12px 24px 8px 0px;
  padding: 8px;
  font-size: 12px;
  color: #44a4fc;
  background-color: #44a4fc;
  border-left: 5px solid #187fe7;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.notification-content {
  @apply leading-5
}
.notification-title {
  @apply mb-1
}

.my-notification.success {
  @apply font-semibold !text-xs;
  color: #4CAF50 !important;
  background-color: #f5fff5 !important;
  border-left: 5px solid #4CAF50 !important;
}

.my-notification.error {
  @apply font-semibold !text-xs;
  background-color: #fff7f7;
  color: #E34242;
  border-left: 5px solid #E34242;
}

.my-notification.warn {
  @apply font-semibold !text-xs;
  background-color: #fffbf3;
  border-left: 5px solid #FF9900;
  color: #FF9900;
}

.my-notification.cancel {
  @apply font-semibold !text-xs;
  background-color: #eeeeee;
  border-left: 5px solid #828282;
  color: #828282;
}
.my-notification:after {
  @apply top-1 right-1 absolute text-base cursor-pointer font-medium;
  /* content: "\00d7"; This will render the 'X' */
  content: "x";
}

/*  */

.highlightHover:hover .changeColorHover {
  fill: #ffffff !important;
  transition: all ease 0.3s;
}

.changeColorHover {
  fill: #753ED7;
  transition: all ease 0.3s;
}

/* close option in taoster */
.my-notification .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  /* Add your custom close icon */
  /* Example: using Font Awesome close icon */
  /* Make sure to include the corresponding CSS for the icon library */
  font-weight: 900;
  content: "\f00d"; /* Close icon unicode */
  font-size: 14px;
}

.my-notification .close-button:hover {
  color: red; /* Change the color on hover if desired */
}
.z-index-full {
  z-index: 99 !important;
}
.z-index-max {
  z-index: 999 !important;
}
/* .v-step__arrow--dark:before {
    background: #4f5a6f !important;
} */

/* nudge style */
.nudge-msg li.high {
  @apply border-l-4 rounded border-[#E34242]
}
.nudge-msg li.low {
  @apply border-l-4 rounded border-[#FF9900]
}
.nudge-msg li.medium {
  @apply border-l-4 rounded border-[#44a4fc]
}

@keyframes jwGIbW {
  0% {
    transform: translate(-100%, -100%);
  }
  100% {
      transform: translate(100%, 100%);
  }
}
.search-negative {
  opacity: .2;
  transition: opacity .5s ease-in-out;
}

.animate-depth {
  transition: opacity .5s ease-in-out;
}
select:focus {
    border-style: solid;
    @apply border-[#a2a2a2]
}
.hyperLink {
  @apply text-[#1185F7]
}
.loginHeader{
  @apply text-[#454545]
}
.orderwindow input[type="number"]:disabled {
  background: url(@/assets/img/stripe.svg) !important;
  color: #a8a8a8 !important;
}
</style>
