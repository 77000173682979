<template>
    <div class="relative z-index-full">
        <draggable :isFixed="true" wwidth="44" name="floating_btn_feedback">
            <template #header>
                <!-- fixed bottom-4 right-4 -->
                <button v-tippy="{ content: `${!isFeedbackFormOpen ? 'Feedback' : ''}` }" class="fixed bottom-4 right-5 floating-bulb-button violet-bg rounded-tl-[40%] rounded-tr-[10%] rounded-bl-[40%] rounded-br-[40%] text-white p-2 h-[30px] w-[30px]" @click="openFeedbackForm">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="max-w-full h-auto text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg> -->
                    <img :src="feedbacksvg" alt="feedback">
                </button>
                </template>
        </draggable>
        
        <!-- <transition name="slide-up" > -->
            <transition :name="'fade'" >
            <div v-if="isFeedbackFormOpen" v-click-outside="handleOutsideClick" class="fixed right-5 bottom-14 flex items-center justify-center">
            <div class="bg-gray-50 dark:bg-[#27272A] w-96 p-4 rounded box-shadow-fill">
                <!-- <h2 class="text-base !font-bold mb-4 primary-color">Feedback</h2> -->
                <form @submit.prevent="submitFeedback">
                    <div class="mb-4">
                        <label for="comment" class="text-xs block mb-1 !font-medium primary-color">Comment:</label>
                        <textarea rows="4" v-click-outside="handleclickout" id="comment" v-model="comment" class="w-full border rounded p-2 dark:bg-[#181818]  dark:border-[#232325] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :class="{ 'border-red-500' : !comment && isSubmit}" maxlength="250"></textarea>
                    </div>
                    <div>
                        <label for="rating" class="text-xs block mb-1 !font-medium primary-color">Rating:</label>
                    </div>
                    <div class="rating" v-click-outside="handleclickout">
                      <input @click="handleRatingChange('5')" type="radio" id="star5" name="rating" value="5" />
                      <label for="star5"></label>
                      <input @click="handleRatingChange('4')" type="radio" id="star4" name="rating" value="4" />
                      <label for="star4"></label>
                      <input @click="handleRatingChange('3')" type="radio" id="star3" name="rating" value="3" />
                      <label for="star3"></label>
                      <input @click="handleRatingChange('2')" type="radio" id="star2" name="rating" value="2" />
                      <label for="star2"></label>
                      <input @click="handleRatingChange('1')" type="radio" id="star1" name="rating" value="1" />
                      <label for="star1"></label>
                    </div>
                    <div class="flex justify-end mt-4 mb-2">
                        <button type="submit" class="px-4 py-1 violet-bg text-white rounded ">Submit feedback</button>
                        <button type="button" @click="closeFeedbackForm()" class="px-4 py-1 ml-2 rounded border border-[#a2a2a2] primary-color">Skip</button>
                    </div>
                </form>
            </div>
            </div>
        </transition>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import draggable from '../components/dragable.vue'
import feedbacksvg from '../assets/img/feedback.svg'
export default {
    data() {
        return {
            isFeedbackFormOpen: false,
            comment: '',
            rating: null,
            isSubmit: false,
            feedbacksvg
        };
    },
    computed: {
        ...mapGetters('login', ['getUCC'])
    },
    methods: {
        openFeedbackForm() {
            this.isFeedbackFormOpen = !this.isFeedbackFormOpen
        },

        closeFeedbackForm() {
            this.isFeedbackFormOpen = false;
            this.isSubmit  = false;
            this.comment = ''
            this.rating = ''
        },

        async submitFeedback() {
            this.isSubmit = true
            if(!this.comment || !this.rating) return;
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-IN', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            let json = {
                "userId": this.getUCC,
                "date": this.$common.formatDate(formattedDate),
                "comments": this.comment ? this.comment?.trim() : this.comment,
                "ratings": this.rating,
                "source": 'WEB',
                "version": this.$store.state.appVersion,
            }
            await this.$store.dispatch('feedback/submitFeedback', json)
            this.comment = '';
            this.rating = null;
            this.closeFeedbackForm();
            this.isSubmit = false
        },

        handleRatingChange(starRating) {
            this.rating = starRating
        },

        handleOutsideClick() {
            this.closeFeedbackForm();
        },

        handleclickout() {
            this.isSubmit = false
        },
    },
    components: { draggable },
    mounted() {
      
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* rating star start */
.rating {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
  justify-content: flex-end;
}

.rating input {
  display: none;
}

.rating label {
  float: right;
  cursor: pointer;
  color: lightgray;
}

.rating label:before {
  content: '\2605'; /* Unicode character for a star */
  font-size: 34px;
}

.rating input:checked ~ label {
  color: orange;
}
/* rating star end */
.box-shadow-fill {
    /* filter: drop-shadow(3px 3px 8px rgba(0.25, 0.25, 0.5, 0.45)) !important; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


/* animation */
/* .slide-down-up-enter-active {
  transition: transform 0.3s ease-out;
}
.slide-down-up-enter-from {
  transform: translateY(-100%);
}
.slide-down-up-enter-to {
  transform: translateY(0);
}
.slide-down-up-leave-active {
  transition: transform 0.3s ease-in;
}
.slide-down-up-leave-from {
  transform: translateY(0);
}
.slide-down-up-leave-to {
  transform: translateY(-100%);
}


.slide-up-down-enter-active {
  transition: transform 0.3s ease-out;
}
.slide-up-down-enter-from {
  transform: translateY(0);
}
.slide-up-down-enter-to {
  transform: translateY(-20%);
}
.slide-up-down-leave-active {
  transition: transform 0.3s ease-in;
}
.slide-up-down-leave-from {
  transform: translateY(100%);
}
.slide-up-down-leave-to {
  transform: translateY(0);
} */


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>